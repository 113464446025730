import React from 'react';
import '../styles/pages_style/HomePageThird.scss';
import COCOBINO_HOTELS from "../local_db/db.json";
import { useTravel } from '../context/TravelCartContext';
import ButtonSimple from '../components/ButtonSimple';

const HomePageThird = () => {

    const {handleClickNavigateForBoxDeVoyageVersTravelPage} = useTravel();
    //let rand = Math.round(Math.random()*(COCOBINO_HOTELS.hotels.length - 1 + 1)) ;    
    let rand = 2;    

    function handleClick() {
        handleClickNavigateForBoxDeVoyageVersTravelPage(COCOBINO_HOTELS.hotels[rand]);
    }
    
    return (
        <div>
            <section className="container3">
        <div className="container3-1">

            <div className="bloc3-1">

                <div className="titreC3-1"><h2>Ou aller en 2025 ?</h2></div>
                <div className="descriptionC3-1"><p>Découvrez notre nouvelle offre du moment</p></div>
            </div>

            
            <div className="bloc3-2">
                <div className="blocImage3-1">
                    <div className="imageBS1">
                                             
                        <div className="blocInfoImg">
                            <div className="infoImg">
                                <div className="imgTexte"><h3>{COCOBINO_HOTELS.hotels[rand].title}</h3></div>
                            </div>
                        </div>
                        <img src={COCOBINO_HOTELS.hotels[rand].imgUrlArray[0]} alt="" id='image'/>
                    </div>
                </div>

                <div className="blocInformation">
                    <div className="blocTitreInfo">
                        <div className="titreInfo">Le repos au Rendez-vous</div>
                    </div>
                    <div className="blocInfoDesc">
                        <div className="descriptionInfo">
                            <p>
                            {COCOBINO_HOTELS.hotels[rand].description}
                            </p>
                        </div>
                    </div>
                    <div className='blocEtoileAndButtonPrixContainer'>
                        <div className="blocEtoile">
                            <div className="etoileInfo"></div>
                            <div className="etoileInfo"></div>
                            <div className="etoileInfo"></div>
                        </div>
                        <div className="blocButtonPrix">
                            <ButtonSimple label={"Explorer"} number={1} onClickFunction={handleClick}/>
                            <div className="prixInfo">{COCOBINO_HOTELS.hotels[rand].rooms[0].price + " € / par personne"}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>

    <section className="container4">
        <div className="container4-1">


            <div className="bloc4-1">

                <div className="titreC4-1">Nos avantages</div>
                <div className="descriptionC4-1"><p>Voyagez de la meilleure façon</p></div>
            </div>


            <div className="bloc4-2">
                <div className="bloc4-3">
                    <div className="bloc4-4">
                        <div className="bloc4-5">
                            <div className="bloc4-5Titre"><h3>Créez votre voyage sur mesure</h3></div>
                        </div>

                        <div className="bloc4-6">
                            <div className="bloc4-6Image1"></div>
                        </div>

                        <div className="bloc4-7">
                            <div className="bloc4-7Desc">
                                <p>
                                Entre activités fascinantes, plaisirs gourmands et paysages magnifiques, découvrez le Grand-Duché comme jamais auparavant. 
                                Sélectionnez l’hébergement dans lequel rester, choisissez les activités à faire pendant votre découverte, mangez dans les 
                                meilleurs restaurants du coin et bien plus encore. Vous choisissez et Cocobino s’en charge !
                                </p>
                            </div>
                        </div>


                    </div>
                    <div className="bloc4-4">
                        <div className="bloc4-5">
                            <div className="bloc4-5Titre"><h3>Service client 7 jours sur 7</h3></div>
                        </div>

                        <div className="bloc4-6">
                            <div className="bloc4-6Image2"></div>
                        </div>

                        <div className="bloc4-7">
                            <div className="bloc4-7Desc">
                                <p>
                                Profitez d’un service client disponible 7 jours sur 7 pour répondre à toutes vos demandes, à chaque étape de votre expérience. Avec une assistance continue, 
                                nous vous assurons une tranquillité d’esprit totale et un accompagnement personnalisé, où que vous soyez et à tout moment. Votre satisfaction est notre priorité, 
                                et Cocobino est là pour vous !
                                </p>
                            </div>
                        </div>


                    </div>
                    <div className="bloc4-4">
                        <div className="bloc4-5">
                            <div className="bloc4-5Titre"><h3>PLUS avec Cocobino</h3></div>
                        </div>

                        <div className="bloc4-6">
                            <div className="bloc4-6Image3"></div>
                        </div>

                        <div className="bloc4-7">
                            <div className="bloc4-7Desc">
                                <p>
                                
Plongez dans l'exceptionnel. Vivez des instants mémorables dans nos hôtels et campings de qualité. Explorez, ressentez, laissez-vous inspirer. Découvrez le Luxembourg avec passion. Réservez dès maintenant et ouvrez la porte vers des expériences uniques qui vous invitent à visiter le Luxembourg.
                                </p>
                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </div>
    </section>

        </div>
    );
};

export default HomePageThird;