import React, { createContext, useState, useContext, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../components/Alert';
import { parse , differenceInDays } from "date-fns";

const TravelContext = createContext();

export const TravelCartContext = ({ children }) => {

    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([]);
    const [messageAlert, setMessageAlert] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);


    const [dateD, setDateD] = useState(() => localStorage.getItem("dateD") || null);
    const [dateA, setDateA] = useState(() => localStorage.getItem("dateA") || null);

    function getPersonalisedAlert(message){
        setMessageAlert(message);
        setOpenAlert(true);
    }

    function getHotel() {
        return JSON.parse(localStorage.getItem("hotelFTP")) || null;
    }

    const [hotelForTravelPage, setHotel] = useState(getHotel())

    const checkOutDates = () => {
        if (dateD === null && dateA === null) {
            return false
        } else {
            return true;
        }
    }

    const howManyNights = useMemo(() => {
        if (!dateA || !dateD) {
            // Si l'une des dates est invalide, retourne 0
            return 0;
        }
        try {
            // Parse les dates au format "dd/MM/yyyy"
            const start = parse(dateA, "dd/MM/yyyy", new Date());
            const end = parse(dateD, "dd/MM/yyyy", new Date());
        
            // Calculer la différence en jours
            const numberOfNights = differenceInDays(end, start) - 1;
        
            // Empêcher les valeurs négatives
            return Math.max(0, numberOfNights);
          } catch (error) {
            console.error("Erreur lors de l'analyse des dates :", error);
            return 0; // Retourne 0 en cas d'erreur
        }

    }, [dateA, dateD]);

    function handleClickNavigateForBoxDeVoyageVersTravelPage(hotel) {

        localStorage.setItem("hotelFTP", JSON.stringify(hotel));
        setHotel(hotel);
        navigate(`/boutique/${hotel.title}/${hotel.id}`);

    }

    const [travelResumeInLocal, setTravelResumeInLocal] = useState(() => {
        const storedData = localStorage.getItem("travelResume");
        return storedData ? JSON.parse(storedData) : null;
    });

    useEffect(() => {
        if (travelResumeInLocal === null) {
            localStorage.setItem("travelResume", travelResumeInLocal);
        }

    }, [travelResumeInLocal]);

    function setTravelResumeForTravelPage(hotelLocation, dateDepard, dateArrive, itemList, totalPrice, nights) {
        let trvR = {
            "locationHotel": hotelLocation,
            "dateDepard": dateDepard,
            "dateArrive": dateArrive,
            "itemList": itemList,
            "totalPrice": totalPrice,
            "nuits": nights
        }
        localStorage.setItem("travelResume", JSON.stringify(trvR));
        setTravelResumeInLocal(trvR);
    }
    //------------------------------
    //------------------------------
    // HOTEL AND CLIENT RESERVATION JOURNEY
    //------------------------------
    //------------------------------

    const addRoomToShoppingCart = (numberOfPerson, title, price, roomUrlImg, type) => {
        if (numberOfPerson === 0) {
            console.log("YOU NEED TO CHOOSE HOW MANY PERSON");
        } else {
            let room = {
                "title": title,
                "numberOfPersons": numberOfPerson,
                "totalPrice": price,
                "imgUrl": roomUrlImg,
                "type": type
            }
            setCartItems([...cartItems, room]);
        }
    }

    const addActivityToShoppingCart = (numberOfPerson, title, price, roomUrlImg, type, activityLocation) => {

            let activity = {
                "title": title,
                "numberOfPersons": numberOfPerson,
                "totalPrice": price,
                "imgUrl": roomUrlImg,
                "type": type,
                "locationActivite": activityLocation
            }
            setCartItems([...cartItems, activity]);
        
    }

    const addSuppToShoppingCart = (title, price, roomUrlImg, type, champagne, cardMessage) => {

        let supp = {
            "title": title,
            "totalPrice": price,
            "imgUrl": roomUrlImg,
            "type": type,
            "champagne": champagne,
            "cardMessage": cardMessage
        }
        setCartItems([...cartItems, supp]);
        console.log(supp);

    }

    const removeFromCart = (index) => {
        setCartItems(cartItems.filter((_, i) => i !== index));
    }

    const totalCartPrice = useMemo(() => {
        return cartItems.reduce((total, item) => total + item.totalPrice, 0)
    }, [cartItems]);

    const totalItems = useMemo(() => {
        return cartItems.length;
    }, [cartItems]);

    const getReservationPage = () => {
        if (checkOutDates() === false) {
            setMessageAlert("Vous devez choissir des dates");
            setOpenAlert(true);
        } else if (cartItems.length === 0) {
            setMessageAlert("le panier est vide");
            setOpenAlert(true);
        } else {
            setTravelResumeForTravelPage(hotelForTravelPage.location, dateA, dateD, cartItems, totalCartPrice, howManyNights);
            console.log("handleClick-forTravelReservationPage ", `/boutique/${hotelForTravelPage.title}/${hotelForTravelPage.id}/reservationPage`);
            navigate(`/boutique/${hotelForTravelPage.title}/${hotelForTravelPage.id}/reservationPage`);
        }
    }

    return (
        <TravelContext.Provider value={{
            addRoomToShoppingCart, addActivityToShoppingCart, addSuppToShoppingCart, removeFromCart,
            totalCartPrice, totalItems, cartItems, getReservationPage,
            setDateA, setDateD, dateA, dateD,
            setHotel, hotelForTravelPage, checkOutDates, howManyNights,
            handleClickNavigateForBoxDeVoyageVersTravelPage,
            travelResumeInLocal, setTravelResumeForTravelPage,
            getPersonalisedAlert
        }}>
            {
                <>
                    <Alert message={messageAlert} openState={openAlert} setOpenFunction={setOpenAlert} />
                    {children}
                </>
            }
        </TravelContext.Provider>
    );
};

export const useTravel = () => useContext(TravelContext);