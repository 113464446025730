import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useAuth } from '../context/AuthProvider';

const AdminConnexionPage = () => {
    const [password, setPassword] = useState("");
    const {login} = useAuth();

    const handleLogin = () => {
        login(password);
    };
    return (
        <div>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                bgcolor="black"
            >
                <Box display="flex">
                    <TextField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Mot de passe"
                        variant="outlined"
                        type="password"
                        sx={{
                            input: { color: 'black', backgroundColor: 'white', borderRadius: '10px' },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'black',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'black',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'black',
                                },
                            },
                        }}
                    />
                    <Button
                        onClick={handleLogin}
                        variant="contained"
                        sx={{
                            marginLeft: '10px',
                            backgroundColor: 'white',
                            color: 'black',
                            borderRadius: '10px',
                            '&:hover': {
                                backgroundColor: '#f2f2f2',
                            },
                        }}
                    >
                        Connexion
                    </Button>
                </Box>
            </Box>
        </div>
    );
};

export default AdminConnexionPage;