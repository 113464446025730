import React, { useState } from 'react';
import { Container, Button, TextField, Box } from '@mui/material';
import Header2 from '../components/Header2';
import '../styles/pages_style/reservationPage.scss';
import CartItem from '../components/newComponents/CartItem';
import Alert from '../components/Alert';
/*import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';*/
import ScrollToTop from "../components/ScrollTop";
import LoadingComponent from '../components/LoadingComponent';
import { useAuth } from '../context/AuthProvider';

const SendDevisPage = () => {
    const urlServer = "https://cocobino-backend.onrender.com/getTravelfrom_id";
    const [CLIENT_RESERVATION, setClient_reservation] = useState(null);
    const [loadingAnimationState, setloadingAnimationState] = useState(true);
    const [alertState, setAlertState] = useState(false);
    const [alertStateDevis, setAlertStateDevis] = useState(false);
    const [paymentUrl, setpaymentUrl] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [alertMessage, setAlertMessage] = useState("erreur");
    const { logout } = useAuth();

    async function fetchTravelFrom_id(travel_id) {
        try {
            const response = await fetch(urlServer, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "id": travel_id
                })
            })
            if (!response.ok) {
                setAlertState(true);
                throw new Error("erreur lors de la récup" + response.statusText);
            } else {
                setloadingAnimationState(false);
                const data = await response.json();
                setClient_reservation(data.data?.[0]);

            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSearch = () => {
        setloadingAnimationState(true);
        if (Number.isInteger(Number(searchValue))) {
            console.log(`Rechercher la réservation: ${searchValue}`);
            // Ajouter ici la logique de recherche
            fetchTravelFrom_id(searchValue);
            console.log(CLIENT_RESERVATION);
        } else {
            setAlertMessage("Veuillez entrer un chiffre entier")
            setAlertState(true);
        }
    };

    async function sendTravelDevis() {
        try {
            const result = await fetch("https://cocobino-backend.onrender.com/sendPaymentLink", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: CLIENT_RESERVATION.id })
            })
            if (!result.ok) {
                throw new Error("erreur lors de l'envoie du devis");
            } else {
                const response = await result.json();
                setpaymentUrl(response);
                setAlertStateDevis(true);
            }
        } catch (error) {
            setAlertMessage('il y a eu une erreur lors de la récupération des données');
            setAlertState(true);
            console.log(error);
        }

    }

    return (
        <div>
            <ScrollToTop />

            <Header2 />
            {alertState === true ? (
                <Alert message={alertMessage} openState={alertState} setOpenFunction={setAlertState} />
            ) : null}
            {alertStateDevis === true ? (
                <Alert message={'le devis à été envoyé avec succès'} openState={alertStateDevis} setOpenFunction={setAlertStateDevis} />
            ) : null}
            <Container className="app-container">
                <Box display="flex" justifyContent="center" alignItems="center" mt={2} marginBottom="30px">
                    <TextField
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Entrez un chiffre"
                        variant="outlined"
                        sx={{
                            input: { color: 'white', backgroundColor: 'black', borderRadius: '10px' },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'white',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'white',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'white',
                                },
                            },
                        }}
                    />
                    <Button
                        onClick={handleSearch}
                        variant="contained"
                        sx={{
                            marginLeft: '10px',
                            backgroundColor: 'white',
                            color: 'black',
                            borderRadius: '10px',
                            '&:hover': {
                                backgroundColor: '#f2f2f2',
                            },
                        }}
                    >
                        Rechercher
                    </Button>
                </Box>
                {loadingAnimationState === true ? (
                    <div style={{
                        height: "80vh", width: "100%", display: "flex",
                        justifyContent: "center", alignItems: "center"
                    }}>
                        <LoadingComponent />
                    </div>
                ) : (
                    <>
                        <h1 >Voyage de {CLIENT_RESERVATION?.travelReservation?.clientInfos.nom} {CLIENT_RESERVATION?.travelReservation?.clientInfos.nomDeFamille}</h1>
                        <p variant="body2" className="description">
                            Voici la réservation du client, ces données sont issues de la base de donnée de cococbino, si il n'y aucune donnée vérifié bien d'avoir écrit le bon id de voyage.
                        </p>
                        <div className='main'>
                            <div className='main_inputForm'>
                                <h2>Information du client</h2>
                                <p>id de voyage : <strong>{CLIENT_RESERVATION?.id}</strong></p>
                                <p>nom : <strong>{CLIENT_RESERVATION?.travelReservation?.clientInfos.nom}</strong></p>
                                <p>nom de famille : <strong>{CLIENT_RESERVATION?.travelReservation?.clientInfos.nomDeFamille}</strong></p>
                                <p>email : <strong>{CLIENT_RESERVATION?.travelReservation?.clientInfos.email}</strong></p>
                                <p>numéro de tel : <strong>{CLIENT_RESERVATION?.travelReservation?.clientInfos.telephone}</strong></p>
                                <p>date de la réservation : <strong>{CLIENT_RESERVATION?.created_at.split("T")[0]}</strong></p>
                                <p>message : {CLIENT_RESERVATION?.travelReservation?.clientInfos.message}</p>
                                {CLIENT_RESERVATION?.paimentStatus === true ? (
                                    <p style={{ marginTop: "20px", color: "green" }}><strong>le client à déja effectué le payment</strong></p>
                                ) : (
                                    <p style={{ marginTop: "20px" }}><strong>le client n'a pas encore effectué le payment</strong></p>
                                )}

                            </div>
                            <div className='main_itemList'>
                                <h2>Résumé de votre panier :</h2>
                                <div className='main_itemList_itemSlide'>
                                    {CLIENT_RESERVATION?.travelReservation?.clientTravel?.itemList.map((item, key) => {
                                        return (
                                            <CartItem
                                            sourceImg={item.imgUrl}
                                            nombreChildren={item.numberOfChildren}
                                            type={item.type}
                                            nombreDePersonnes={item.numberOfPersons}
                                            prix={item.totalPrice}
                                            title={item.title}
                                            isOnReservationPage={true}
                                            key={key}
                                          />
                                        )
                                    })}
                                </div>
                                <div style={{ marginTop: "10px" }} >
                                    <p>arrivé : <strong>{CLIENT_RESERVATION?.travelReservation?.clientTravel?.dateArrive}</strong> dépard <strong>{CLIENT_RESERVATION?.travelReservation?.clientTravel?.dateDepard}</strong></p>
                                </div>
                                <div style={{ marginTop: "10px" }} >
                                    <p>total : <strong>{CLIENT_RESERVATION?.travelReservation?.clientTravel?.totalPrice} €</strong></p>
                                </div>
                            </div>
                        </div>
                        <Button type="submit" variant="contained" color="primary" fullWidth className="submit-btn" onClick={() => sendTravelDevis()}>
                            Envoyer le devis au client
                        </Button>
                        {paymentUrl != null ? (
                            <p style={{ marginTop: "20px" }} >voici le lien de payment du client <strong>{paymentUrl}</strong></p>
                        ) : null}
                        <Button
                            onClick={()=>logout()}
                            variant="contained"
                            sx={{
                                marginLeft: '10px',
                                backgroundColor: 'white',
                                color: 'black',
                                borderRadius: '10px',
                                marginTop:"20px",
                                '&:hover': {
                                    backgroundColor: '#f2f2f2',
                                },
                            }}
                        >
                            se déconnecter
                        </Button>
                    </>
                )}
            </Container>
        </div>
    );
};

export default SendDevisPage;