import React from 'react';
import TravelSlideShow from '../components/TravelSlideShow';

const HomePageSecond = () => {
    return (
        <section className="container2">
            <div className="container2-1">
                <div className="bloc2-1">
                    <div className="titreC2-1"><h2>Vivez le luxe du Luxembourg</h2></div>
                    <div className="descriptionC2-1"><p>Invitez le/la à passer une expérience inoubliable dès maintenant !</p></div>
                </div>
                <TravelSlideShow/>
            </div>
        </section>
    );
};

export default HomePageSecond;