import React from 'react';
import HomePage from "../pages/HomePage"
import HomePageThird from "./HomePageThird";
import Footer from "../components/Footer";
import HomePageSecond from "./HomePageSecond";
import Header2 from "../components/Header2";
import ScrollToTop from "../components/ScrollTop";

const Home = () => {
    document.title = 'cocobino - Un voyage au Luxembourg personnalisé et authentique';

    return (
        <div>
            <Header2 />
            <ScrollToTop />
            <HomePage />
            <HomePageSecond />
            <HomePageThird />
            <Footer />
        </div>

    );
};

export default Home;
