import { React, useState, useMemo } from 'react';
import SliderCarroussel from './SliderCarroussel';
import Button from '@mui/material/Button';
import IncrementButtonGroup from './IncrementButtonGroup';
import '../../styles/components_style/roomBouble.scss';
import ButtonSimple from '../ButtonSimple';
import { useTravel } from '../../context/TravelCartContext';

const RoomBouble = ({ roomTitle, roomDescription, roomImg, roomPrice, roomAdvantages, roomMaxOfPerson, nights }) => {

    const { addRoomToShoppingCart, getPersonalisedAlert } = useTravel();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        if (nights === 0) {
            getPersonalisedAlert("veuillez selectionnez vos dates");
        } else {
            setIsModalOpen(true);
        }
    }
    const closeModal = () => {
        setIsModalOpen(false);
    }

    const [readRoomDescription, setReadRoomDescription] = useState(false);
    const [numberOfPerson, setNumberOfPerson] = useState(0);

    const displayRoomDescription = () => {
        if (readRoomDescription === false) {
            setReadRoomDescription(true);
        } else {
            setReadRoomDescription(false);
        }
    }

    const totalRoomPrice = useMemo(() => {
        return nights * roomPrice;
    }, [nights, roomPrice]);

    function handleClickAddToCart() {
        if (numberOfPerson !== 0) {
            addRoomToShoppingCart(numberOfPerson, roomTitle, totalRoomPrice, roomImg[0], "room");
            closeModal();
        } else {
            getPersonalisedAlert("veuillez choissir le nombre de personnes");
        }
    }

    return (
        <>
            <div className='roomList_containerWrapper'>

                <div className="roomList_roomContainer">
                    <div className="roomList_roomContainer_elmntCenter">
                        <div className="roomList_roomContainer_elmntCenter_imgContainer">
                            <img className='roomList_roomContainer_elmntCenter_imgContainer_img' src={roomImg[0]} alt="" loading='lazy' />
                        </div>
                        <div className="roomList_roomContainer_elmntCenter_reservation">
                            <div className="roomList_roomContainer_elmntCenter_reservation_title">
                                <h3 className='roomtitle_roomlist'>{roomTitle}</h3>
                            </div>
                            <div className="roomList_roomContainer_elmntCenter_reservation_price">
                                <p>prix :  <strong style={{ 'color': "rgba(128, 15, 47, 0.867)" }}>{roomPrice} €</strong> </p>
                                <p style={{ 'marginLeft': '10px' }}>max : <strong style={{ color: "rgba(128, 15, 47, 0.867)" }}>{roomMaxOfPerson}</strong></p>
                            </div>
                            <div className="roomList_roomContainer_elmntCenter_reservation_calltoActionBtn">
                                <div className="buttonWrapper">
                                    <Button variant="contained" size='small' onClick={openModal} style={{ 'backgroundColor': 'black', 'fontSize': '11px', 'boxShadow': 'none', width: "100%" }}> <p>voir la chambre</p> </Button>
                                </div>
                            </div>
                        </div>
                        <div className='roomList_roomContainer_elmntCenter_advantageContainer'>
                            <div className='roomList_roomContainer_elmntCenter_advantageContainer_topSetting'>
                                {roomAdvantages.map((string, key) => {
                                    return (
                                        <div className='roomList_roomContainer_elmntCenter_advantageContainer_elmnt' key={key}>
                                            <p className='roomList_roomContainer_elmntCenter_advantageContainer_elmnt_p' key={key}>{string}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {isModalOpen && (
                <div className={`roomList_roomContainer_screenWrapper`}>

                    <div className="roomList_roomContainer_popupRoom">
                        <div className="buttonContainer">
                            <ButtonSimple label={"fermer"} number={1} onClickFunction={closeModal} />
                        </div>
                        <div className='imageSliderWrapper'>
                            <SliderCarroussel imageList={roomImg} />
                        </div>
                        <div className="roomList_popupRoom_titleWrapper">
                            <h3>{roomTitle}</h3>
                        </div>
                        <div className="roomList_popupRoom_maxPersonne">
                            <p>maximum<strong> {roomMaxOfPerson} </strong>personnes</p>

                        </div>
                        <p><strong>{roomPrice}€ </strong>  / nuit</p>
                        <p
                            onClick={displayRoomDescription}
                            style={{ cursor: "pointer" }}
                        > <strong>{readRoomDescription === false ? ('lire la descripion') : ('fermer la description')}</strong>
                        </p>
                        <div className="roomList_popupRoom_descriptionContainer">
                            {readRoomDescription && (
                                <p>{roomDescription}</p>
                            )}
                        </div>
                        <div className="roomList_popupRoom_numberOfPersonContainer">
                            <p>nombre de personnes : </p>
                            <IncrementButtonGroup
                                setResult={setNumberOfPerson}
                                maxNumberOfPerson={roomMaxOfPerson}
                            />
                        </div>
                        <div className="roomList_popupRoom_priceAndBtnBlock">
                            <div className="roomList_popupRoom_priceAndBtnBlock_priceContainer">
                                <p className='roomList_popupRoom_priceAndBtnBlock_priceContainer_price' style={{ color: "rgba(128, 15, 47, 0.867)" }}>{totalRoomPrice} €</p>
                                <div className='roomList_popupRoom_priceAndBtnBlock_priceContainer_text'>
                                    <p>tarif pour {nights} nuits</p>
                                </div>

                            </div>
                            <div className='roomList_popupRoom_priceAndBtnBlock_btnWrapper'>
                                <Button variant="contained" style={{
                                    'backgroundColor': 'black',
                                    'fontSize': '12px',
                                    'width': '90%'
                                }}
                                    onClick={handleClickAddToCart}
                                >Réserver la chambre</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RoomBouble;