import React from 'react';
import '../styles/components_style/BoxDeVoyage.scss';
import stars from '../styles/assets/logo/etoile.svg';
import mapicon from '../styles/assets/logo/location-pin-svgrepo-com.svg';
import ButtonSimple from './ButtonSimple';
import { useTravel } from '../context/TravelCartContext';

const BoxDeVoyage = ({hotel}) => {
    const {handleClickNavigateForBoxDeVoyageVersTravelPage} = useTravel();

    const hotelInformation = {
        "title":hotel.title,
        "img":hotel.imgUrlArray[0],
        "location":hotel.location,
        "description":hotel.description,
        "price":hotel.rooms[0].price
    };

    const handleClick = () => {
        handleClickNavigateForBoxDeVoyageVersTravelPage(hotel);
    };

    const star = Array.from({ length: hotel.numberOfStars }, (_, index) => (
        <img src={stars} alt="" key={index} className='sectionBoxDeVoyage__box__stars__img' id='stars' />
    ));

    return (
        <div >
            <div className="sectionBoxDeVoyage__box">
                <div className="sectionBoxDeVoyage__box__header">
                    <div className="sectionBoxDeVoyage__box__header__title">
                        <h2>{hotelInformation.title}</h2>
                    </div>
                    <div className="sectionBoxDeVoyage__box__header__add"></div>
                </div>
                <div className="sectionBoxDeVoyage__box__stars">
                    {star}
                </div>
                <div className="sectionBoxDeVoyage__box__img">
                    <img src={hotelInformation.img} alt="" className='sectionBoxDeVoyage__box__img__img' />
                </div>
                <div className="sectionBoxDeVoyage__box__location">
                    <img src={mapicon} alt="" className='sectionBoxDeVoyage__box__location__icon' />
                    <strong><p>{hotelInformation.location}</p></strong>
                </div>
                <div className="sectionBoxDeVoyage__box__description">
                    <p>
                        {hotelInformation.description}
                    </p>
                </div>
                <div className="sectionBoxDeVoyage__box__bottom">
                    <ButtonSimple label={"Réservez"} onClickFunction={handleClick} number={1}/>
                    <div className="sectionBoxDeVoyage__box__bottom__price">
                        <p id='priceTag'><strong>{hotelInformation.price} € / par person</strong></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoxDeVoyage;