import { React, useState } from 'react';
import SliderCarroussel from './SliderCarroussel';
import { Button, Select, MenuItem, TextField } from '@mui/material';
import ButtonSimple from '../ButtonSimple';
import '../../styles/components_style/SupplementBouble.scss';
import { useTravel } from '../../context/TravelCartContext';

const SupplementBubble = ({ packTitle, packageImg, packagePrice, packageDescription, packageAVTDescription }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDrink, setSelectedDrink] = useState('');
    const [personalMessage, setPersonalMessage] = useState('');
    const { addSuppToShoppingCart, getPersonalisedAlert } = useTravel();

    const openModal = () => { setIsModalOpen(true); }
    const closeModal = () => { setIsModalOpen(false); }

    const handleDrinkChange = (event) => {
        setSelectedDrink(event.target.value);
    };

    const handleMessageChange = (event) => {
        setPersonalMessage(event.target.value);
    };

    const handleClickAddToCart = () => {
        // Ajout de l'action d'ajout au panier avec les informations de boisson et message
        if (selectedDrink === '') {
            getPersonalisedAlert("veuillez choissir une boisson");
        } else if (personalMessage === '') {
            getPersonalisedAlert("veuillez écrire un message");
        } else {
            addSuppToShoppingCart(packTitle, packagePrice, packageImg[0], "supp", selectedDrink, personalMessage);
            closeModal();
        }
    };

    return (
        <>
            <div className='supplementList_containerWrapper'>
                <div className="supplementList_roomContainer">
                    <div className="supplementList_roomContainer_elmntCenter">
                        <div className="supplementList_roomContainer_elmntCenter_imgContainer">
                            <img className='supplementList_roomContainer_elmntCenter_imgContainer_img' src={packageImg[0]} alt="" loading='lazy' />
                        </div>

                        <div className="supplementList_roomContainer_elmntCenter_reservation">
                            <div className="supplementList_roomContainer_elmntCenter_reservation_title">
                                <h3 className='roomtitle_supplementList'>{packTitle}</h3>
                            </div>
                            <div className="supplementList_roomContainer_elmntCenter_reservation_price">
                                <p>prix :  <strong style={{ 'color': '#800f2fdd' }}>{packagePrice} €</strong> </p>
                            </div>
                            <div className="supplementList_roomContainer_elmntCenter_reservation_calltoActionBtn">
                                <div className="buttonWrapper">
                                    <Button variant="contained" size='small' onClick={openModal} style={{ 'backgroundColor': 'black', 'fontSize': '11px', 'boxShadow': 'none', width: "100%" }}> <p>Ajouter au panier</p> </Button>
                                </div>
                            </div>
                        </div>

                        <div className='supplementList_roomContainer_elmntCenter_advantageContainer'>
                            <div className='supplementList_roomContainer_elmntCenter_advantageContainer_topSetting'>
                                <p>{packageDescription}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <div className={`supplementList_roomContainer_screenWrapper`}>
                    <div className="supplementList_roomContainer_popupRoom">
                        <div className="buttonContainer">
                            <ButtonSimple label={"fermer"} number={1} onClickFunction={closeModal} />
                        </div>
                        <div className='imageSliderWrapper'>
                            <SliderCarroussel imageList={packageImg} />
                        </div>
                        <div className="supplementList_popupRoom_titleWrapper">
                            <h3>{packTitle}</h3>
                        </div>

                        <div className="supplementList_popupRoom_descriptionContainer" >
                            <p>{packageDescription}</p>
                            <p className="supplementList_popupRoom_descriptionContainer_mid">Le contenu du {packTitle} :</p>
                            {packageAVTDescription.map((desc, key) => {
                                return (
                                    <div style={{ marginTop: "15px" }} key={key}>
                                        <p style={{ fontWeight: "bolder" }}>{desc.sousTitre}</p>
                                        <ul className="supplementList_popupRoom_descriptionContainer_list">
                                            {desc.avantage.map((avt, key) => {
                                                return (
                                                    <li key={key}><p>{avt}</p></li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="supplementList_popupRoom_options">
                            <div>
                                <p>Choisissez votre boisson</p>
                                <Select
                                    value={selectedDrink}
                                    onChange={handleDrinkChange}
                                    fullWidth
                                    variant="outlined"
                                    displayEmpty
                                >
                                    <MenuItem value="Grand cru - Shlink">Grand cru - Shlink</MenuItem>
                                    <MenuItem value="Vin d'Italy">Italia - Vin superieur</MenuItem>
                                    {/* Ajoutez d'autres options de boisson ici */}
                                </Select>
                            </div>

                            <div style={{ marginTop: "15px" }}>
                                <p>Ecrivez votre message</p>
                                <TextField
                                    value={personalMessage}
                                    onChange={handleMessageChange}
                                    placeholder="Ecrivez le message pour votre amour..."
                                    multiline
                                    rows={3}
                                    fullWidth
                                    variant="outlined"
                                />
                            </div>
                        </div>

                        <div className="supplementList_popupRoom_priceAndBtnBlock">
                            <div className="supplementList_popupRoom_priceAndBtnBlock_priceContainer">
                                <p className='supplementList_popupRoom_priceAndBtnBlock_priceContainer_price' style={{ color: '#800f2fdd' }}>{packagePrice} €</p>
                                <div className='supplementList_popupRoom_priceAndBtnBlock_priceContainer_text'>
                                    <p>valeur du pack</p>
                                </div>
                            </div>
                            <div className='supplementList_popupRoom_priceAndBtnBlock_btnWrapper'>
                                <Button variant="contained" style={{
                                    'backgroundColor': 'black',
                                    'fontSize': '12px',
                                    'width': '70%'
                                }}
                                    onClick={handleClickAddToCart}
                                >Ajouter au Panier</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SupplementBubble;
