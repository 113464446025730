import React, { useState } from 'react';
import ShoppingCart from '../../components/newComponents/ShoppingCart';
import ActivityBubble from '../../components/newComponents/ActivityBubble';
import Button from '@mui/material/Button';
import RoomBouble from '../../components/newComponents/RoomBouble';
import { TravelInformation } from '../../components/newComponents/TravelInformation';
import "../../styles/pages_style/TravelPage.scss";
import Header2 from '../../components/Header2';
import Footer from '../../components/Footer';
import { useParams } from 'react-router-dom';
import Alert from '../../components/Alert';
import ScrollToTop from '../../components/ScrollTop';
import SupplementBubble from '../../components/newComponents/SupplementBubble';
import SUPP from '../supp.json';
import { useTravel } from '../../context/TravelCartContext';
import COCOBINO_ACTIVITY from "../../local_db/db.json";

const TravelPage = () => {

    const { howManyNights, hotelForTravelPage, getReservationPage } = useTravel();
    const { hotel_title } = useParams();
    const [openAlert, setOpenAlert] = useState(false);

    let supp = SUPP;

    return (
        <>
            <Header2 />
            <ScrollToTop />
            <Alert message={"votre panier est vide"} openState={openAlert} setOpenFunction={setOpenAlert} />
            <div className='connexionPage'>
                <ShoppingCart />
                <TravelInformation hotelTitle={hotel_title} testImagesArrays={hotelForTravelPage.imgUrlArray} testHotelDescription={hotelForTravelPage.description} testRoomAdvantage={hotelForTravelPage.advantages}
                    hotelLocation={hotelForTravelPage.location} />
                    
                <div className='title_headSection'>
                    <h3>Chambres disponibles</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi reprehenderit placeat id a laboriosam similique.</p>
                </div>
                <div className='roomList'>
                    {hotelForTravelPage.rooms.map((room, index) => {
                        return (
                            <RoomBouble
                                roomTitle={room.titleRoom}
                                roomImg={room.imgUrlArrayRoom}
                                roomAdvantages={room.advantageRoom}
                                roomPrice={room.price}
                                roomDescription={room.descriptionRoom}
                                roomMaxOfPerson={room.maxOfPerson}
                                key={index}
                                nights={howManyNights}
                            />
                        )
                    })}
                </div>
                <div className='title_headSection'>
                    <h3>Les activités</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit consectetur impedit accusamus, minus at atque quia sint possimus placeat cupiditate molestiae ab vero, delectus fugiat fugit, sed expedita sequi assumenda inventore. Ad quod maxime labore? Sunt, nesciunt itaque! Provident ipsam eveniet aliquid labore aliquam quisquam odit numquam recusandae in delectus?</p>
                </div>
                <div className='roomList'>
                    {
                        COCOBINO_ACTIVITY.activity.map((activty, key) => {
                            return (
                                <ActivityBubble
                                    activityTitle={activty.title}
                                    activityImgUrlArr={activty.imgUrlArray}
                                    activityDescription={activty.description}
                                    activityAdvantageArr={activty.advantages}
                                    activityLocation={activty.location}
                                    activityPriceAdulte={activty.prixAdulte}
                                    activityPriceEnfant={activty.prixEnfant}
                                    key={key}
                                    coco_section={activty.coco_section}
                                />
                            )
                        })
                    }
                </div>
                <div className='title_headSection'>
                    <h3>Les Suppléments</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit consectetur impedit accusamus,. Ad quod maxime labore? Sunt, nesciunt itaque! Provident ipsam eveniet aliquid labore aliquam quisquam odit numquam recusandae in delectus?</p>
                </div>
                <div className='suppList'>
                    {supp.map((elmnt, key) => {
                        return (
                            <SupplementBubble
                                packTitle={elmnt.titre}
                                packageDescription={elmnt.grandDescription}
                                packageImg={elmnt.img}
                                key={key}
                                packagePrice={elmnt.prix}
                                packageAVTDescription={elmnt.description}
                            />
                        )
                    })}
                </div>
                <div className='btnContainerEndOfPage'  >
                    <Button variant="contained" onClick={()=>getReservationPage()} style={{ 'backgroundColor': 'black', 'boxShadow': 'none', 'width': '100%', marginTop: "35px" }}>passer à la réservation du voyage</Button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default TravelPage;