import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  console.log("private route checking" + isAuthenticated);
  if (isAuthenticated === false) {
    return <Navigate to="/admin" />;
  }

  return children;
};

export default PrivateRoute;
