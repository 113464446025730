import React from 'react';
import '../styles/pages_style/About.scss';
import Footer from "../components/Footer";
import Header2 from "../components/Header2";
import ScrollToTop from "../components/ScrollTop";
//images
import img from '../styles/assets/images/articles-luxembourg-paysages1.webp';
import natureLuxembourg from '../styles/assets/images/image-luxembourg-nord-camping-luxembourg.webp';
import mudamLuxembourg from '../styles/assets/images/image-luxembourg-Mudam-photo-visiter-Luxembourg.webp';
import villeLuxembourgNuit from '../styles/assets/images/image-ville-du-luxembourg-centre-ville.webp';

import ButtonClassic from '../components/ButtonClassic';

const About = () => {
    document.title = "À propos de Cocobino - Créateurs de Voyages Personnalisés Axés sur l'Expérience";
    return (

        <div>

            <Header2 />
            <ScrollToTop />
            <section className="containerAbout">
                <div className="containerAbout-1">
                    <div className="BlocVideAbout"></div>
                    <div className="BlocHautAbout">
                        <div className="B-TitreAbout">
                            <div className="TitreAbout"><h1 className="TitreAbout">QUI SOMMES-NOUS, DÉCOUVREZ COCOBINO</h1></div>
                            <div className="SsTitreAbout"><p>un voyage qui vous correspond</p></div>
                        </div>

                    </div>
                    <div className="BlocBasAbout">
                        <div className="BlocGaucheAbout">
                            <div className="BlocImages">
                                <div className="BlocImageHaut">
                                    <div className="BlocInterneImageGauche">
                                        <div className="blocImageABout1">
                                            <img src={natureLuxembourg} alt="" className="image1H"></img>
                                        </div>
                                        <div className="blocImageABout2"><img src={villeLuxembourgNuit} alt="" className="image1H"></img></div>
                                    </div>
                                    <div className="BlocInterneImageDroite">
                                        <div className="blocImageAbout3">
                                            <img src={mudamLuxembourg} alt="" className="imge1Droite"></img>
                                        </div>
                                    </div>

                                </div>
                                <div className="BlocImageBas">
                                    <div className="blocImageAbout4"><img src={img} alt="" className="image1Bas"></img></div>
                                </div>
                            </div>
                        </div>
                        <div className="BlocDroiteAbout">

                            <div className="blocStructureDroite">
                                <div className="bandeauAbout1">
                                    <div className="titreBandeau"><h2>Une vision long terme</h2></div>
                                </div>
                                <div className="bandeauAbout2">
                                    <div className="zoneTexteAbout">
                                        <p>
                                            Cocobino fait ses premiers pas au Grand-Duché, notre agence combine une connaissance approfondie du territoire luxembourgeois avec une ambition de portée mondiale. Nous
                                            visons à offrir des voyages d’exception non seulement au Luxembourg mais bientôt à travers le monde.

                                        </p>
                                    </div>
                                </div>
                                <div className="bandeauAbout3">
                                    <div className="titreBandeau"><h2>Voyager de manière responsable et impactante</h2></div>
                                </div>
                                <div className="bandeauAbout4">
                                    <div className="zoneTexteAbout">
                                        <p>
                                            En choisissant nos services, vous contribuez à un tourisme responsable qui valorise les communautés locales et favorise un impact positif durable, à la fois pour vous et pour les destinations que vous visitez.
                                        </p>
                                    </div>
                                </div>
                                <div className="bandeauAbout5">
                                    <div className="titreBandeau"><h2>Engagement envers l’excellence</h2></div>
                                </div>
                                <div className="bandeauAbout6">
                                    <div className="zoneTexteAbout">
                                        <p>
                                            Qu'il s'agisse de nos services de base ou de propositions plus sophistiquées, nous mettons un point d’honneur à offrir à chacun de nos clients une expérience exceptionnelle. La satisfaction de nos voyageurs est notre priorité, et nous nous assurons que chaque voyage soit mémorable avec des prestations soigneusement sélectionnées et des partenaires fiables.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="containerAbout">
                <div className="containerAbout-1">
                    <div className="blocBasP2">
                        <div className="TitreP2AboutBas"><h2>Prêt à voyager avec Cocobino</h2></div>
                        <div className="blocImageBasAboutP2">
                            <div className="encadreImageAbout">
                                <div className="fondImageAbout">
                                    <div className="bandeauHautBackground">
                                        <div className="bandeauHautBackground-1">Explorez nos hôtels / hébergements</div>
                                    </div>
                                    <div className="bandeauBasBackground">
                                        <ButtonClassic
                                            name={'Explorez nos voyages'}
                                            number={2}
                                            newPage={"/boutique"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="encadreInfoAbout">
                                <div class="encadreInfoAbout-1">
                                    <div class="TB2">Nos partenaires</div>
                                    <div class="TB2-1">
                                        <p>Ils ont tous été choisis minutieusement pour vous offrir le meilleur de Cocobino. Il y en a pour tous les goûts ! En passant par Cocobino vous allez pouvoir profiter des avantages Cocos surprises mis en place en commun accord avec nos Partenaires. Une expérience unique pour chacun de vos voyages est garantie !</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <Footer />
        </div>

    );

};



export default About;