import React, { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const login = (password) => {
    if(process.env.REACT_APP_SECRET_PASSW_COCOBINO_ADMIN === undefined || process.env.REACT_APP_SECRET_PASSW_COCOBINO_ADMIN === null){
      console.log("passw not found");    
    }
    if (password === process.env.REACT_APP_SECRET_PASSW_COCOBINO_ADMIN) {
      setIsAuthenticated(true);
      console.log("login used " + isAuthenticated);
      navigate('/admin/travelChecking');
    } else {
      alert("Mot de passe incorrect");
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    navigate('/admin');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);