import React, { useState } from 'react';
import BoxDeVoyage from './BoxDeVoyage';
import "../styles/components_style/TravelSlideShow.scss";
import ButtonSimple from './ButtonSimple';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import COCOBINO_HOTELS from "../local_db/db.json";

const TravelSlideShow = () => {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    const handleNext = () => {
        if (index < COCOBINO_HOTELS.length - 1) {
            setIndex(index + 1);
        }
    };

    const handlePrev = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false); // Fermer l'alerte
    };

    return (
        <>

            <Snackbar
                open={open}
                autoHideDuration={4000}  // La durée avant que l'alerte ne disparaisse (en millisecondes)
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position de l'alerte
            >
                <Alert onClose={handleClose} severity="info">Veuillez mettre des dates</Alert>
            </Snackbar>
            <div className='SlideShow'>
                <div className="SlideShow__travelContainer">
                    <div className="SlideShow__packs" style={{ transform: `translateX(-${index * 270}px)` }}>
                        {
                            COCOBINO_HOTELS.hotels.map((hotel_db, key) => {
                                return (
                                    <BoxDeVoyage
                                        hotel={hotel_db}
                                        key={key}
                                    />
                                );
                            })
                        }
                    </div>
                </div>
                <div className='SlideShow__buttonContainer'>
                    <ButtonSimple label={"previous"} number={1} onClickFunction={handlePrev} />
                    <ButtonSimple label={"next"} number={1} onClickFunction={handleNext} />
                </div>
            </div>
        </>
    );

}

export default TravelSlideShow;
